import {
    FieldNumberWithControlsContainer as SourceFieldNumberWithControlsContainer,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.container';

import FieldNumberWithControls from './FieldNumberWithControls.component';

/** @namespace Pwa/Component/FieldNumberWithControls/Container */
export class FieldNumberWithControlsContainer extends SourceFieldNumberWithControlsContainer {
}

export default FieldNumberWithControls;
