import { SyntheticEvent } from 'react';

import { FieldType } from 'Component/Field/Field.config';
import {
    FieldContainer as SourceFieldContainer,
} from 'SourceComponent/Field/Field.container';
import { ValidationOutput } from 'Type/Field.type';
import { validate } from 'Util/Validator';
import { FieldValidationOutput } from 'Util/Validator/Validator.type';

/** @namespace Pwa/Component/Field/Container */
export class FieldContainer extends SourceFieldContainer {
    validate(data?: (Event | SyntheticEvent) & ValidationOutput): boolean | FieldValidationOutput {
        const {
            validationRule: { range: { max: maxValidLength = 0 } = {} }, type, attr: { name } = {},
        } = this.props;
        const { showLengthError } = this.state;
        const newValidRule = this.handleShowLengthError();
        const value = type === FieldType.CHECKBOX || type === FieldType.RADIO
            ? !!(this.fieldRef as HTMLInputElement)?.checked
            : this.fieldRef?.value;

        // It prevent checkboxes's validation
        // if (!value && value !== '') {
        //     return false;
        // }

        const response = validate(type === FieldType.FILE
            && typeof value === 'string'
            ? value.toLowerCase()
            : value || '', newValidRule);
        const output = response !== true ? { ...response, type, name } : response;

        // If validation is called from different object you can pass object
        // to store validation error values
        if (data && data.detail && typeof output !== 'boolean') {
            if (!data.detail.errors) {
                // eslint-disable-next-line no-param-reassign
                data.detail.errors = [];
            }

            // Validates length on submit, renders special message
            if (output.errorMessages
                && typeof value === 'string'
                && maxValidLength
                && value.length > maxValidLength
                && !showLengthError
            ) {
                this.setState({ showLengthError: true });
                output.errorMessages.unshift(__('Please enter no more than %s characters.', maxValidLength));
            }

            data.detail.errors.push(output);
        }

        // When submit and response equals true (it can be object) reset show length error
        if (response === true) {
            this.setState({ showLengthError: false });
        }

        this.setState({ validationResponse: output });

        return output;
    }
}

export default FieldContainer;
