/* eslint-disable react/jsx-no-bind */
import { ReactElement } from '@scandipwa/scandipwa/src/type/Common.type';

import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import {
    FieldSelectComponent as SourceFieldSelectComponent,
} from 'SourceComponent/FieldSelect/FieldSelect.component';
import { Option } from 'Type/Field.type';
import { noopFn } from 'Util/Common';

import './FieldSelect.override.style';

/** @namespace Pwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends SourceFieldSelectComponent {
    renderOption(option: Option): ReactElement {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered,
            isAvailable = true,
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick,
        } = this.props;

        return (
            (!isPlaceholder)
            && (
                <li
                  block="FieldSelect"
                  elem="Option"
                  mods={ {
                      isDisabled: !isAvailable,
                      isExpanded,
                      isPlaceholder,
                      isHovered,
                  } }
                  key={ id }
                            /**
                             * Added 'o' as querySelector does not work with
                             * ids, that consist of numbers only
                             */
                  id={ `o${id}` }
                  role="menuitem"
                  onMouseDown={ () => handleSelectListOptionClick(option) }
                  onTouchStart={ () => handleSelectListOptionClick(option) }
                  onKeyPress={ () => handleSelectListOptionClick(option) }
                  tabIndex={ isExpanded ? 0 : -1 }
                >
                                { label }
                                { subLabel && (
                                    <strong>
                                        { ` ${subLabel}` }
                                    </strong>
                                ) }
                </li>
            )
        );
    }

    renderNativeOption(option: Option): ReactElement {
        const {
            id,
            value,
            // disabled,
            label,
            subLabel = '',
            // isAvailable = true,
        } = option;

        // const { isDisabled } = this.props;

        return (
            <option
              key={ id }
              id={ String(id) }
              value={ value }
            >
                { `${label} ${subLabel}` }
            </option>
        );
    }

    render(): ReactElement {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            isDisabled,
        } = this.props;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${ id }_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ !isDisabled ? handleSelectExpand : noopFn }
                  onKeyPress={ !isDisabled ? handleSelectListKeyPress : noopFn }
                  role="button"
                  tabIndex={ 0 }
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div block="FieldSelect" elem="Clickable">
                        { this.renderSortSelect() }
                        { this.renderNativeSelect() }
                        <ChevronIcon direction={ isExpanded ? Directions.TOP : Directions.BOTTOM } />
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
