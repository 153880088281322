import {
    DROPDOWN_MIN_HEIGHT as SOURCE_DROPDOWN_MIN_HEIGHT,
    DROPDOWN_SCROLL_MIN_ITEMS as SOURCE_DROPDOWN_SCROLL_MIN_ITEMS,
} from 'SourceComponent/FieldSelect/FieldSelect.config';

// TODO: implement DROPDOWN_SCROLL_MIN_ITEMS
export const DROPDOWN_SCROLL_MIN_ITEMS = SOURCE_DROPDOWN_SCROLL_MIN_ITEMS;

// TODO: implement DROPDOWN_MIN_HEIGHT
export const DROPDOWN_MIN_HEIGHT = SOURCE_DROPDOWN_MIN_HEIGHT;
